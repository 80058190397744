<template>
  <match-media v-slot="{ mobile, tablet, desktop }">
    <div class="main-layout">
      <aside class="main-layout__sidebar">
        <the-sidebar v-if="desktop" @close="sidebarVisible = false" />
        <v-drawer v-else :visible.sync="sidebarVisible">
          <the-sidebar @close="sidebarVisible = false" />
        </v-drawer>
      </aside>
      <main class="main-layout__wrapper">
        <header v-if="mobile || tablet" class="main-layout__header content">
          <div class="main-layout__header-controls">
            <button class="main-layout__burger" @click="toggleSidebar">
              <v-burger-icon />
            </button>
            <div class="main-layout__logo">
              <v-logo />
            </div>
          </div>

          <v-header-user-data />
        </header>

        <div
          class="main-layout__main content"
          :class="{ 'main-layout__main--without-padding-bottom': withoutBottomPadding }"
        >
          <router-view />
        </div>

        <footer v-if="!hideFooter" class="main-layout__footer">
          <v-logo class="main-layout__footer-logo" />
        </footer>

        <v-loader
          v-if="isViewLoader"
          :color="isNmh ? $options.MAIN_THEME_COLOR.nmh : $options.MAIN_THEME_COLOR.agentik"
          :active="isViewLoader"
        />
      </main>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VDrawer from '@/components/common/VDrawer.vue'
import TheSidebar from '@/components/common/TheSidebar.vue'
import VLogo from '@/components/common/VLogo.vue'
import VBurgerIcon from '@/components/icons/VBurger.vue'
import VHeaderUserData from '@/components/common/VHeaderUserData.vue'
import VLoader from '@/components/common/VLoader.vue'
import { loaderState } from '@/services/loading'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { MAIN_THEME_COLOR } from '@/constants/colors'

export default {
  MAIN_THEME_COLOR,
  name: 'MainLayout',
  components: { VLoader, VDrawer, VBurgerIcon, TheSidebar, VLogo, VHeaderUserData, MatchMedia },
  props: {
    hideFooter: { type: Boolean, default: false },
    withoutBottomPadding: { type: Boolean, default: false }
  },
  data: () => {
    return {
      sidebarVisible: false
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    isViewLoader() {
      return loaderState.view
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.sidebarVisible = false
    next()
  },
  methods: {
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';
@import 'src/assets/scss/mixins';

.main-layout {
  &__sidebar {
    @include only-desktop {
      position: fixed;
      width: $--sidebar-width-desktop;
      top: 0;
      left: 0;
      bottom: 0;
      overflow: auto;
      z-index: 100;
    }
  }

  &__wrapper {
    position: relative;
    margin-left: $--sidebar-width-desktop;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    @include tablet {
      margin-left: 0;
    }
  }

  &__header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $--medium-gray;
    background-color: $--white;

    @include phone {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__main {
    @include stretched-column-flex;

    padding-top: 70px;
    padding-bottom: 72px;

    @include tablet {
      padding-top: 110px;
      padding-bottom: 24px;
    }

    @include phone {
      padding-top: 102px;
      padding-bottom: 16px;
    }

    &--without-padding-bottom {
      padding-bottom: 0;
    }
  }

  &__header-controls {
    display: flex;
  }

  &__logo {
    width: 60px;

    img {
      width: 100%;
    }

    @include phone {
      display: none;
    }
  }

  &__burger {
    margin-right: 28px;
    width: 24px;
    height: 24px;
  }

  &__footer {
    margin-top: auto;
    padding: 23px 0;
    text-align: center;
    border-top: 1px solid $--medium-gray;
  }

  &__footer-logo {
    width: 60px;
  }
}
</style>
